  .initials {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .menu {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: calc(100% - 340px);
    left: 80px;
    height: 100%;
    max-height: 280px;
    width: 475px;
    background-color: #FFFFFF;
    border: 2px solid #bbbbbb;
    border-radius: 12px;
    padding: 24px 32px 14px 30px;
  }
  
  .menu:before {
    content: "";
    position: absolute;
    right: calc(100% - 8px);
    top: calc(100% - 30px);
    height: 15px;
    width: 15px;
    background: #FFFFFF;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    border-top: transparent;
    border-right: transparent;
    border-bottom: inherit;
    border-left: inherit;
  }
  
  .menuItem {
    position: relative;
    top: 36px;
    margin: 5px 0px 0px -10px;
    border-top: 1px solid #e6e6e6;
    font-size: 1em;
    color: #1e91b8;
    cursor: pointer;
  }

  .colleagueName {
    font-weight: light;
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    color: #707070;
  }

  .email {
    font-size: 16px;
    font-style: normal;
    color: #0067b1;
  }

  .profileLabel{
    display: block;
    font-weight: light;
    font-size: 18px;
    font-style: normal;
    color: #626262;
  }

  .roles{
    font-size: 14px;
    font-weight: normal;
    color: #000000;
  }
  
  