.content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 100%;
    background-color: #fafafa;
    overflow: auto;
}

.image {
    margin-top: 72px;
    width: 225px;
    height: 225px;
    -o-object-fit: contain;
       object-fit: contain;
}

.title {
    display: block;
    font-size: 2.25em;
    font-weight: bold;
    color: #212121;
    margin: 72px 16px 0px 16px;
}

.subtitle {
    display: block;
    font-size: 1.5em;
    margin: 16px 40px 0px 40px;
}

.btn {
    margin: 48px 0px;
    display: block;
}
