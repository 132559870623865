.dialog {
    width: 500px;
    min-height: 200px;
}

.title {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 8px;
}

.buttonContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 10px;
    margin-right: 20px;
    > div {
        margin-left: 5px;
    }
}

.justifySpaceBetween {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    > div {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
    > .justifySpaceBetween {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.row {
    margin-bottom: 0.5rem;
}

.formLabel {
    width: 110px;
}

.formField {
    width: 400px;
}

.multiSelectCombo{
width: 350px;
}