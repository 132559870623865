.ldsroller {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 64px;
    height: 64px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
}
.ldsroller div {
    -webkit-animation: ldsroller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: ldsroller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 32px 32px;
        -ms-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
}
.ldsroller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #0067b1;
    margin: -3px 0 0 -3px;
}
.ldsroller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
}
.ldsroller div:nth-child(1):after {
    top: 50px;
    left: 50px;
}
.ldsroller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
}
.ldsroller div:nth-child(2):after {
    top: 54px;
    left: 45px;
}
.ldsroller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
}
.ldsroller div:nth-child(3):after {
    top: 57px;
    left: 39px;
}
.ldsroller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
}
.ldsroller div:nth-child(4):after {
    top: 58px;
    left: 32px;
}
.ldsroller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
}
.ldsroller div:nth-child(5):after {
    top: 57px;
    left: 25px;
}
.ldsroller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
}
.ldsroller div:nth-child(6):after {
    top: 54px;
    left: 19px;
}
.ldsroller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
}
.ldsroller div:nth-child(7):after {
    top: 50px;
    left: 14px;
}
.ldsroller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
}
.ldsroller div:nth-child(8):after {
    top: 45px;
    left: 10px;
}
@-webkit-keyframes ldsroller {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes ldsroller {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
