.content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}

.featureImage {
    max-height: 60%;
    width: 100%;
    -ms-flex-item-align: center;
        align-self: center;
}

.innerContent {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}

.logo {
    max-height: 83px;
    max-width: 100px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: left;
       object-position: left;
    margin-top: 40px;
}

.title {
    word-break: break-word;
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin-top: 48px;
}

.subtitle {
    word-break: break-word;
    display: block;
    font-size: 1.5em;
    margin-top: 24px;
}

.btn {
    display: block;
    margin-top: 48px;
    margin-bottom: 32px;
}

@media only screen and (min-width: 768px) {
    .content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

    .featureImage {
        -o-object-position: center;
           object-position: center;
        -o-object-fit: cover;
           object-fit: cover;
        height: 100%;
        width: auto;
        max-width: 40%;
        max-height: none;
    }

    .innerContent {
        margin-left: 88px;
        margin-right: 40px;
        overflow: auto;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .logo {
        max-height: 88px;
        max-width: 280px;
        margin-top: 0px;
    }

    .title {
        font-size: 2.25em;
        margin-top: 88px;
    }

    .subtitle {
        margin-top: 16px;
    }

    .btn {
        margin-bottom: 0px;
    }
}
