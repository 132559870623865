.wrap {
    height: 100%;
    padding: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: auto;
}

.inner {
    height: 100%;
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .wrap {
        padding: 32px;
    }
}
